import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpUserTokenInterceptorService implements HttpInterceptor {
    constructor(
        private readonly router: Router,
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            request.url.search('auth') !== -1
        ) {
            return next.handle(request);
        }

        if (localStorage.getItem('baragtools-user-token')) {
            const requestWithToken: HttpRequest<any> = request.clone({
                headers:
                    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('baragtools-user-token'))
            });

            return next.handle(requestWithToken).pipe(
                catchError((httpErrorResponse: any) => {
                    if (httpErrorResponse?.error) {
                        if (httpErrorResponse.error.code === 'invalid_connection_token') {
                            localStorage.removeItem('baragtools-user-token');
                            this.router.navigate(['/connexion']).then();
                        }
                    }

                    return next.handle(requestWithToken);
                })
            );
        }

        this.router.navigate(['/connexion']).then();
        return next.handle(request);
    }
}
