import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UtilsService, UserTokenService } from '../services';

export const userTokenGuardGuard: CanActivateFn = (): boolean => {
    const utilsService: UtilsService = inject(UtilsService);

    if (!utilsService.onBrowser()) {
        return false;
    }

    const userTokenExist: boolean = !!localStorage.getItem('baragtools-user-token');
    const router: Router = inject(Router);

    if (!userTokenExist) {
        const router: Router = inject(Router);
        router.navigate(['/connexion']).then();
        return userTokenExist;
    }

    const userTokenService: UserTokenService = inject(UserTokenService);
    const tokenUserInfos: any = userTokenService.parseUserToken();
    if (new Date() >= new Date(tokenUserInfos.exp * 1000)) {
        router.navigate(['/connexion']).then();
    }
    return userTokenExist;
};
