import { Routes } from '@angular/router';

import { userTokenGuardGuard } from './core/guards';
import { SocketService, SocketResolverService } from './core/services';

export const routes: Routes = [
    {
        path: 'connexion',
        loadComponent: () => import('./modules/login/pages/login/login.component')
            .then((m: any) => m.LoginComponent),
    },
    {
        path: '',
        canActivate: [userTokenGuardGuard],
        providers: [
            SocketService,
            SocketResolverService,
        ],
        loadComponent: () => import('./modules/layout/pages/layout/layout.component')
            .then((m: any) => m.LayoutComponent),
        resolve: {
            socket: SocketResolverService,
        },
        children: [
            {
                path: ':gameType',
                loadChildren: () => import('./modules/room/room.routes').then((m: any) => m.roomRoutes),
            },
            {
                path: '',
                loadComponent: () => import('./modules/home/pages/home/home.component')
                    .then((m: any) => m.HomeComponent),
            }
        ]
    }
];
