import { ApplicationConfig } from '@angular/core';
import { provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import {
    provideHttpClient,
    withFetch,
    withInterceptorsFromDi,
    HTTP_INTERCEPTORS
} from '@angular/common/http';


import { HttpUserTokenInterceptorService } from './core/interceptors/http-user-token-interceptor.service';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withPreloading(PreloadAllModules)),
        provideHttpClient(
            withFetch(),
            withInterceptorsFromDi()
        ),
        { provide: HTTP_INTERCEPTORS, useClass: HttpUserTokenInterceptorService, multi: true },
        //provideClientHydration() // hydratation was removed because it return error with 'SocketResolverService'
    ]
};
